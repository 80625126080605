import { createApp, markRaw, watch } from 'vue'
import routes from './routes'
import {
  createRouter,
  createWebHistory,
  RouteLocationRaw,
  RouteMeta,
  Router,
} from 'vue-router'
import App from './App.vue'
import axios from 'axios'
import vSelect from 'vue-select'
import { generateAllRoutes } from '@/Helpers/SiteTreeHelper'
import VueClickAway from 'vue3-click-away'
// @ts-ignore
import Vue3GoogleLogin from 'vue3-google-login'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { createGtm } from '@gtm-support/vue-gtm'
import VueLazyLoad from 'wd-vue3-lazyload'
import VueGtag from 'vue-gtag'
import { VueQueryPlugin } from 'vue-query'
import {
  fireErrorMessage,
  setMetaDescription,
  setMetaTitle,
} from '@/Helpers/Global'
import 'virtual:svg-icons-register'
import '../scss/app.scss'
import * as Sentry from '@sentry/vue'
import { createPinia } from 'pinia'
import { useGlobalStore } from './Store/useGlobalStore'
import { useLoginStore } from './Store/useLoginStore'
import { fabric } from 'fabric'
import {
  DEFAULT_LANG,
  DEFAULT_ROUTE_NAME,
  HELP_SITE_URL,
} from '@/Helpers/Constants'
import { redirectToError } from '@/Helpers/Redirect'
import VueHotjar from 'vue-hotjar-next'
import { SiteTreeSectionResource } from '@/Types/SiteTreeSection'

declare global {
  interface Window {
    canvas: fabric.Canvas & {
      height: number
      width: number
      wrapperEl: HTMLElement
    } //TODO: make optional
    FB: {
      login: any
      init: any
    }
    fbAsyncInit: any
  }
}

declare module 'pinia' {
  export interface PiniaCustomProperties {
    router: Router
  }
}

interface RouteMetaExtended extends RouteMeta {
  meta_title: string
  meta_description: string
}

export const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  },
})

watch(
  () => router.currentRoute.value.params?.lang,
  (n) => {
    document
      .querySelector('html')!
      .setAttribute('lang', (n as string | null) ?? DEFAULT_LANG)
  },
  { immediate: true },
)

axios.defaults.withCredentials = true
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.baseURL = import.meta.env.VITE_API_URL

const app = createApp(App)
app.use(
  createPinia().use(({ store }) => {
    store.router = markRaw(router)
  }),
)

if (import.meta.env.VITE_HOTJAR_SITEID) {
  app.use(VueHotjar, {
    id: parseInt(import.meta.env.VITE_HOTJAR_SITEID),
    isProduction: import.meta.env.PROD,
  })
}

generateAllRoutes(router).then(() => {
  router.beforeEach(async (to, _from, next) => {
    const globalStore = useGlobalStore()
    const loginStore = useLoginStore()

    if (
      (to.meta as SiteTreeSectionResource | undefined)?.section_type === 'faq'
    ) {
      window.location.href = HELP_SITE_URL
    }

    let languages = globalStore.locales

    if (!languages) {
      await globalStore.setLocales()
      languages = globalStore.locales
    }

    //find right language
    const lang = (() => {
      const fromParam = to.params.lang
      if (fromParam) return fromParam as string
      const fromPath = window.location.pathname.replace(/^\/([^/]*).*$/, '$1')
      if (fromPath) return fromPath
    })()

    // if language not in the route, or such a language does not exist, redirect to default language
    if (!lang || (languages && !languages.find(({ code }) => code === lang))) {
      next({
        ...to,
        name: to.name ?? DEFAULT_ROUTE_NAME,
        params: { ...(to.params ?? {}), lang: DEFAULT_LANG },
      } as RouteLocationRaw)
      return
    }

    if (
      !to.matched.length &&
      to.fullPath !== '/' &&
      to.fullPath !== `/${lang}/error`
    ) {
      redirectToError()
    }

    //redirect to home if user not logged in and tries to access profile pages
    if (to.matched.some((item) => item.meta.requiresAuth)) {
      await loginStore.isProfileLoaded()

      if (!loginStore.userLoggedIn) {
        next({ name: DEFAULT_ROUTE_NAME, params: { lang } })
        return
      }
    }

    next()
  })

  router.afterEach((to) => {
    const metaTitle = (to.meta as RouteMetaExtended).meta_title
    const metaDescription = (to.meta as RouteMetaExtended).meta_description
    if (metaTitle) setMetaTitle(metaTitle)
    if (metaDescription) setMetaDescription(metaDescription)
  })

  if (import.meta.env.VITE_ADYEN_ENV !== 'test') {
    // SENTRY INITIALIZATION
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.captureConsoleIntegration({
          levels: ['error'],
        }),
      ],
      tracesSampleRate: 0.5,
      beforeSend(event, hint) {
        if (hint && hint.originalException === 'Timeout') return null
        return event
      },
      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        'Network Error',
      ],
      denyUrls: [
        // Google Adsense
        /pagead\/js/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        'google-analytics.com',
      ],
    })
  }

  app
    .use(router)
    .use(VueClickAway)
    .use(VueQueryPlugin)
    .use(VueLazyLoad, {})
    .use(Vue3GoogleLogin, {
      clientId: import.meta.env.VITE_GOOGLE_CLIENT_KEY,
      buttonConfig: {
        type: 'icon',
        shape: 'pill',
        size: 'large',
      },
    })
    .use(VueReCaptcha, {
      siteKey: import.meta.env.VITE_GOOGLE_RECAPTHCA_SITE_KEY,
      loaderOptions: {
        autoHideBadge: true,
      },
    })
    .use(
      createGtm({
        id: import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID,
        debug: false,
        vueRouter: router,
        defer: true,
      }),
    )
    .use(
      VueGtag,
      {
        pageTrackerScreenviewEnabled: true,
        config: { id: import.meta.env.VITE_GOOGLE_ANALYTICS_ID },
        //@ts-ignore
        deferScriptLoad: true,
        appName: 'SELFNAMED',
      },
      router,
    )

  app.component('VSelect', vSelect)

  app.mount('#app')
})

//axios interceptors
const exceptions401 = ['/profile']

/**
 * Axios Request Interceptor
 */
const MAX_REQUESTS_COUNT = 10
const INTERVAL_MS = 10
let PENDING_REQUESTS = 0

axios.interceptors.request.use(
  (config) =>
    new Promise((resolve) => {
      const interval = setInterval(() => {
        if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
          PENDING_REQUESTS++
          clearInterval(interval)
          resolve(config)
        }
      }, INTERVAL_MS)
    }),
)

/**
 * Axios Response Interceptor
 */
axios.interceptors.response.use(
  (response) => {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.resolve(response)
  },
  (error) => {
    if (error.response && error.response.data && error.response.data.message) {
      if (error.response.status === 401) {
        let showPopup = true
        for (const i in exceptions401) {
          if (error.response.config.url.endsWith(exceptions401[i])) {
            showPopup = false
            break
          }
        }

        if (showPopup) {
          fireErrorMessage(error.response.data.message)
        }
      }

      if (!error.response.status) {
        fireErrorMessage('Something went wrong, status code missing.')
      }

      if (error.response.status > 399 && error.response.status !== 401) {
        if (error.response.data && error.response.data.message) {
          fireErrorMessage(error.response.data.message)
        } else {
          fireErrorMessage('Something went wrong.')
        }
      }
    }
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.reject(error)
  },
)
