<template>
  <div class="menu" :class="{ open: layoutStore.mobileMenuOpen }">
    <MainNavigation />
    <div
      v-if="isMobile"
      v-show="!layoutStore.mobileMenuActiveItem"
      class="mobile-content"
    >
      <h2 class="mobile-heading">
        {{ __('header', 'navigation.mobile-profile-title') }}
      </h2>
      <div v-if="loginStore.userLoggedIn" class="profile-navigation">
        <router-link
          v-for="menuItem in profileMenuStore.profileMenuItems"
          :key="menuItem.label"
          class="navigation-link profile"
          :to="menuItem.to"
        >
          <span>{{ menuItem.label }}</span>
        </router-link>
      </div>
      <div v-else class="login">
        <button class="btn btn-primary btn-sign-up" @click="() => openSignUp()">
          {{ __('global', 'Sign up') }}
        </button>
        <p class="signin-cta-text">
          {{ __('global', 'Already a member?') }}
          <button class="btn-sign-in" @click="() => openSignUp(true)">
            {{ __('global', 'Sign in') }}
          </button>
        </p>
      </div>
      <div
        v-if="
          globalStore.sessionSettings &&
          globalStore.countries &&
          getCurrentSessionValue('CURRENT_COUNTRY').currency.name &&
          getCurrentSessionValue('CURRENT_COUNTRY').title
        "
        v-show="!layoutStore.mobileMenuActiveItem"
        class="mobile-preferences"
      >
        <h2 class="mobile-heading">{{ __('header', 'Preferences') }}</h2>
        <PreferencesDropdownMobile />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import PreferencesDropdownMobile from './PreferencesDropdownMobile.vue'
import { __ } from '@/Helpers/i18n'
import { useLoginStore } from '@/Store/useLoginStore'
import { RouteLocationRaw } from 'vue-router'
import MainNavigation from '@/Partials/MainNavigation/MainNavigation.vue'
import useProfileMenuStore from '@/Components/Profile/useProfileMenuStore'
import useLayoutStore from '@/Store/useLayoutStore'
import useScreenBreakpoints from '@/Composables/UseScreenBreakpoints'
import { getCurrentSessionValue } from '@/Helpers/Global'
import { useGlobalStore } from '@/Store/useGlobalStore'

const emit = defineEmits<{
  'open-sign-up': [{ redirectUrl?: RouteLocationRaw; isLogin: boolean }]
}>()

const loginStore = useLoginStore()
const profileMenuStore = useProfileMenuStore()
const layoutStore = useLayoutStore()
const { isMobile } = useScreenBreakpoints()
const globalStore = useGlobalStore()

const openSignUp = (login = false) => {
  layoutStore.mobileMenuOpen = false
  emit('open-sign-up', { isLogin: login })
}
</script>

<style lang="scss" scoped>
.menu {
  $current-header-height: v-bind('layoutStore.currentHeaderHeight');

  position: absolute;
  transform: translateX(101%);
  left: 0;
  top: $current-header-height;
  right: 0;
  bottom: 0;
  background: $white;
  height: calc(100vh - $current-header-height);
  height: calc(var(--vh, 1vh) * 100 - $current-header-height);
  overflow-y: auto;
  transition: 0.3s all;
  padding: 0 1.6rem;
  z-index: 11;
  @include desktop-only {
    position: static;
    transform: none;
    overflow-y: inherit;
    height: auto;
    background: none;
    padding: 0;
  }

  &.open {
    @include mobile-only {
      transform: translateX(0);
    }
  }
}

.mobile-content {
  padding: 3.2rem 0.4rem 0 0.4rem;

  .navigation-link {
    font-size: 1.8rem;
    margin-bottom: 1.2rem;
    letter-spacing: -0.01em;
  }
}

.mobile-preferences,
.login,
.profile-navigation {
  margin-left: -2rem;
  margin-right: -2rem;
  padding-left: 2rem;
  padding-right: 2rem;

  &:not(:last-child) {
    border-bottom: 6px solid $gray-4;
  }

  &:last-child {
    border-bottom: unset;
    padding-bottom: 0;
  }
}

.mobile-preferences {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  position: relative;
}

.profile-navigation {
  padding-bottom: 2.8rem;
}

.login {
  padding-bottom: 4rem;
  border-bottom: 1px solid $light-gray;
  text-align: center;
}

.btn-sign-up {
  width: 100%;
  height: 5.6rem;
  margin-bottom: 2rem;
}

.signin-cta-text {
  letter-spacing: -0.02em;
  font-weight: 500;
}

.btn-sign-in {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  text-decoration: underline;
}

.mobile-heading {
  letter-spacing: -0.04em;
  margin-bottom: 2.8rem;
  font-weight: 500;
  line-height: 1.1;
  font-size: 2.4rem;
  margin-left: 0;
}
</style>
