<template>
  <div v-if="requiredRequestsDone" class="content-container">
    <Header :show-header-content="layoutStore.showHeaderContent" />
    <div
      class="site-content"
      :style="{ paddingTop: layoutStore.expandedHeaderHeight }"
    >
      <router-view />
    </div>
    <Footer v-if="layoutStore.showFooter" />
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import { onMounted, watch, ref } from 'vue'
import Header from './Components/Header.vue'
import Footer from './Components/Footer.vue'
import { initGoogleLogo } from '@/Helpers/GoogleStructuredData'
import { useGlobalStore } from './Store/useGlobalStore'
import { removeOrder } from '@/Helpers/Checkout'
import { useLoginStore } from './Store/useLoginStore'
import { useCartStore } from './Store/useCartStore'
import useLayoutStore from '@/Store/useLayoutStore'

const globalStore = useGlobalStore()
const loginStore = useLoginStore()
const cartStore = useCartStore()
const route = useRoute()
const layoutStore = useLayoutStore()

watch(route, async (n) => {
  await loginStore.setProfile()
  n.name !== 'checkout'
    ? await removeOrder()
    : await cartStore.initializeCartIfAuthenticated()
})

const requiredRequestsDone = ref(false)

onMounted(async () => {
  ;(document.querySelector('body') as HTMLElement).classList.remove('preload')
  fixForIphoneHeight()

  window.addEventListener('scroll', () => {
    fixForIphoneHeight()
  })

  window.addEventListener('resize', () => {
    fixForIphoneHeight()
  })

  initGoogleLogo()
  await Promise.all([
    cartStore.initializeCartIfAuthenticated(),
    globalStore.setTranslations(),
    globalStore.setCountries(),
    globalStore.setSettings(),
    globalStore.setSessionSettings(),
    loginStore.setProfile(),
  ])

  requiredRequestsDone.value = true
  initHeartBeat()
})

const fixForIphoneHeight = () => {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

const initHeartBeat = () => {
  setInterval(() => globalStore.setSessionSettings(), 20000)
}
</script>

<style lang="scss">
/*
When the desktop main navigation is shown:
1. show an overlay over the content;
2. disable scrolling in background.
see MainNavigation.vue for the navigation implementation
*/
.content-container {
  @include mobile-only {
    overflow-x: hidden;
  }

  @include desktop-only {
    &:has(.dropdown-container:focus-within),
    &:has(.dropdown-container:hover) {
      overflow: hidden;

      &:after {
        content: '';
        position: absolute;
        inset: 0;
        bottom: -9999999999999px;
        background: rgba(12, 13, 18, 0.2);
        z-index: 1;
      }
    }
  }

  &:has(.menu.open) {
    overflow: hidden;
  }
}

body {
  @include desktop-only {
    &:has(.dropdown-container:focus-within),
    &:has(.dropdown-container:hover) {
      overflow: hidden;
    }
  }

  &:has(.menu.open) {
    overflow: hidden;
  }
}
</style>
